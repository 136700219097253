import { yupResolver } from '@hookform/resolvers/yup';
import { selectPlugins } from 'pages/dashboard/components/ent-account/components/edit-account/components/plugins/components/view-plugins/selector';
import * as React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { doesPathMatch } from 'utils/data-utils';

import { SessionAudienceTypes, SessionType } from 'config';
import { ICreateSessionForm } from '../../../../interface';
import { selectCreateSession, selectInvitations, selectSessionInformation } from '../../../../selector';
import { createSessionActions } from '../../../../slice';
import sessionInformationFormValidation from '../../validation/session-information-validation';
import SessionInformationModalForm from '../session-information-form';
import { IBadge } from '../../../../../../interface';

interface SessionInformationViewProps {
  editMode:boolean,
  courseOnlySession:boolean
}

export default function SessionInformationView({
  editMode,
  courseOnlySession,
}:SessionInformationViewProps) {
  const location = useLocation();
  const plugins = useSelector(selectPlugins);

  const activePlugins = React.useMemo(
    () => plugins.filter((i) => i.isActive && doesPathMatch(location.pathname, i.components)),
    [plugins, location.pathname],
  );

  const dispatch = useDispatch();
  const sessionInformation = useSelector(selectSessionInformation);
  const invitations = useSelector(selectInvitations);
  const { bookedSessions } = useSelector(selectCreateSession);
  const methods = useForm<ICreateSessionForm>({
    defaultValues: {
      ...sessionInformation,
      sessionType: SessionType.liveClass,
      audienceType: courseOnlySession
        ? SessionAudienceTypes.courseOnly
        : sessionInformation.audienceType,
      bookedSessions,
      badge: (() => {
        const badge: IBadge = {
          badgeTitle: sessionInformation?.badge?.badgeTitle ?? '',
          badgeDescription: sessionInformation?.badge?.badgeDescription ?? '',
          badgeImgUrl: sessionInformation?.badge?.badgeImgUrl ?? '',
        };

        const metaData: Record<string, any> = {};

        activePlugins.forEach((plugin) => {
          const pluginKey = plugin.key;
          metaData[pluginKey] = metaData[pluginKey] || {};

          plugin?.pluginConfig?.forEach((item) => {
            const itemKey = item.key;
            metaData[pluginKey][itemKey] = sessionInformation?.badge?.metaData?.[pluginKey]?.[itemKey] ?? '';
          });
        });

        badge.metaData = metaData;

        return badge;
      })(),
      metaData: (() => {
        const metaData: Record<string, any> = {};

        activePlugins.forEach((plugin) => {
          const pluginKey = plugin.key;
          metaData[pluginKey] = metaData[pluginKey] || {};

          plugin?.pluginConfig?.forEach((item) => {
            const itemKey = item.key;
            metaData[pluginKey][itemKey] = sessionInformation?.metaData?.[pluginKey]?.[itemKey] ?? '';
          });
        });

        return metaData;
      })(),
    },
    resolver: yupResolver(sessionInformationFormValidation),
    context: {
      sessionType: sessionInformation.sessionType,
      invitations,
      editMode,
    },
    shouldFocusError: true,
  });

  const onSubmit:
  SubmitHandler<ICreateSessionForm> = React.useCallback((data) => {
    dispatch(createSessionActions.AddSessionInformation(data));
  }, [dispatch]);

  return (
    <div>
      <FormProvider {...methods}>
        <SessionInformationModalForm
          onSubmit={onSubmit}
          editMode={editMode}
          courseOnlySession={courseOnlySession}
        />
      </FormProvider>
    </div>
  );
}
