import {
  IBankDetails,
  ICharges,
  IColors,
  IFont,
  ILandingPageContent, ILandingPageSections, IMainLandingPageContent, ISocialMedia,
  ISponsorContent, IPromotionalContent,
  IHeaderSections,
} from '../../interface';
import { AdminDocument } from '../edit-account/components/admins/interface';
import { ExpertDocument } from '../edit-account/components/experts/interface';

export interface ICategoryModalForm {
  name: string;
}

export interface ISkillModalForm {
  name: string;
}

export interface IInviteExpertModalForm {
  experts: [
    {
      email: string;
    },
  ];
}

export interface IOnboardAdmin {
  id: string;
  userData: {
    name: string;
  };
  email: string;
  authorityLevel: string;
}

export enum ModalStates {
  close = 'CLOSE',
  sponsorContent = 'SPONSOR_CONTENT',
  promotionalContent = 'PROMOTIONAL_CONTENT',
  category = 'CATEGORY',
  skill = 'SKILL',
  addExpert = 'ADD_EXPERT',
  addAdmin = 'ADD_ADMIN',
}

export interface IOnboardGeneralInformationModalForm extends ISocialMedia {
  name: string;
  entUrl: string;
  email: string;
  currency:string;
  entLogoUrl: string;
  webLogoUrl: string;
  country: string;
  city: string;
  categories: ICategoryModalForm[];
  skills:ISkillModalForm[]
}
export interface IOnboardSitePreferencesModalForm {
  colors: IColors;
  headerTemplate: string;
  isFontDefault: string;
  headerFont: IFont;
  bodyFont: IFont;
  sponsorContent: ISponsorContent[];
  promotionalContent: IPromotionalContent;
}

export interface IOnboardContentModalForm {
  landingPageContent: IMainLandingPageContent;
  landingPageSections: ILandingPageSections;
  groupLandingPageContent: ILandingPageContent;
  expertLandingPageContent: ILandingPageContent;
  sessionLandingPageContent: ILandingPageContent;
  articleLandingPageContent: ILandingPageContent;
  contentLandingPageContent: ILandingPageContent;
  headerSections: IHeaderSections;
}

export interface IOnboardChargesModalForm {
  bankDetails: IBankDetails;
  charges: ICharges;
}

export interface IAdminModalForm {
  firstName: string;
  lastName: string;
  profileImgUrl: string;
  email: string;
  authorityLevel: string;
  accessRecordings: boolean;
  authenticateComplaints: boolean;
  moderateArticles: boolean;
  observeSessions: boolean;
  accessClasses: boolean;
}

export interface IOnboardExpertModalForm {
  experts: ExpertDocument[];
}

export interface IOnboardAdminModalForm {
  admins: AdminDocument[];
}

export interface OnboardEntAccState {
  id: string;
  active: boolean;
  loading: boolean;
  stateLoading: boolean;
  stateExpertLoading: boolean;
  stateAdminLoading:boolean;
  status: number;
  modal: string;
  generalInformationForm: IOnboardGeneralInformationModalForm;
  sitePreferencesForm: IOnboardSitePreferencesModalForm;
  contentForm: IOnboardContentModalForm;
  chargesForm: IOnboardChargesModalForm;
  adminForm: IOnboardAdminModalForm;
  expertForm: IOnboardExpertModalForm;
  isOnBoardingDone: boolean;
  stripeAccountCompleted:boolean
  stripeConnectUrl:string;
  entUrl: string;
}
