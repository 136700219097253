/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFiltered } from 'interfaces/api-response';
import { SessionType } from 'config';
import { EntAccountDocument, ICategory } from '../../interface';
import {
  EditEntAccState,
  IArticlesLandingPageModalForm,
  IBankDetailsModalForm,
  ICategoryModalForm,
  IChargesModalForm,
  IContentLandingPageModalForm,
  IExpertLandingPageModalForm,
  ISessionsLandingPageModalForm,
  IGeneralInformationModalForm,
  IGeographyModalForm,
  IGroupLandingPageModalForm,
  IHeaderTemplateModalForm,
  ILandingPageModalForm,
  ILandingPageSectionsModalForm,
  IPromotionalContentModalForm,
  ISessionLandingPageModalForm,
  ISocialMediaModalForm,
  ISponsorContentModalForm,
  IUserAllocationForm,
  IWebLogoModalForm,
  IWebsiteColorsModalForm,
  IWebsiteFontsModalForm,
  ModalStates,
  ISkillModalForm,
  IEditProduct,
  ITermsAndConditionsPageModalForm,
  CtaSectionTemplate,
  ILandingCtaContent,
  IHeaderSectionsModalForm,
  ICommunicationPreferencesModalForm,
} from './interface';
import { IPaginateFilterPayload } from './components/groups/components/edit-groups/interface';
import {
  ISessionAvatar, ISessionAvatarWithAction,
} from './components/experts/components/edit-expert/components/sessions/interface';
import { IExpertAvatar, IExpertAvatarWithAction } from './components/experts/interface';
import { IGroupAvatar, IGroupAvatarWithAction } from './components/groups/interface';
import { IArticleAvatar, IArticleAvatarWithAction } from './components/articles/interface';

export const initialState: EditEntAccState = {
  stateLoading: false,
  loading: false,
  subStateLoading: false,
  id: '',
  active: true,
  modal: '',
  stripeAccountCompleted: false,
  stripeConnectUrl: '',
  generalInformation: {
    name: '',
    entUrl: '',
    email: '',
    currency: 'AUD',
  },
  geography: { city: '', country: '' },
  socialMedia: {
    facebookUrl: '',
    twitterUrl: '',
    linkedinUrl: ' ',
    youtubeUrl: '',
    instagramUrl: '',
  },
  categories: [],
  skills: [],
  entLogoUrl: '',
  webLogoUrl: '',
  userAllocation: {
    maxNoOfEndUsers: 0,
    maxNoOfCoaches: 0,
    maxNoOfGroups: 0,
  },
  headerTemplate: {
    headerTemplate: ' ',
  },
  promotionalContent: [{ url: '', fileCategory: 'image' }],
  sponsorContent: [{ contentUrl: '', url: '' }],
  colors: { primaryColor: '', secondaryColor: '' },
  websiteFonts: {
    isFontDefault: '',
    headerFont: { name: '', url: '' },
    bodyFont: { name: '', url: '' },
  },
  landingPageSections: {
    groups: false,
    sessions: false,
    featured: false,
    experts: false,
    articles: false,
    testimonials: false,
  },
  headerSections: {
    groups: false,
    newsfeed: false,
    experts: false,
    articles: false,
  },
  landingPageContent: {
    tagLine: '',
    title: '',
    body: '',
  },
  groupLandingPageContent: {
    title: '',
    body: '',
  },
  expertLandingPageContent: {
    title: '',
    body: '',
  },
  sessionLandingPageContent: {
    title: '',
    body: '',
  },
  contentLandingPageContent: {
    title: '',
    body: '',
  },
  articlesLandingPageContent: {
    title: '',
    body: '',
  },
  termsAndConditionsPage: {
    link: '',
  },
  privacyPolicyPage: {
    link: '',
  },
  communicationPreferences: {
    enableNewsOffersUpdates: false,
    enableMarketingCommunications: false,
  },
  charges: { adminFee: 0, forEntClient: 0, forExpert: 0 },
  bankDetails: {
    accountName: '',
    accountNumber: '',
    bankName: '',
    branchName: '',
  },
  giftCards: [
    {
      entId: '',
      adminId: '',
      name: '',
      code: '',
      percent_off: 0,
      amount_off: 0,
      currency: '',
      duration: '',
      duration_in_months: 0,
    },
  ],
  experts: [],
  sessions: [],
  contentOnlySessions: [],
  groups: [],
  articles: [],
  filteredSessions: { result: [], total: 0 },
  filteredContentOnlySessions: { result: [], total: 0 },
  filteredExperts: { result: [], total: 0 },
  filteredGroups: { result: [], total: 0 },
  filteredArticles: { result: [], total: 0 },
  landingCtaContent: {
    title: '',
    body: '',
    buttonText: '',
    buttonLink: '',
    imageUrl: '',
    template: CtaSectionTemplate.TEXT_ONLY,
  },
};

export const editEntAccSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc',
  initialState,
  reducers: {
    openModal(state: EditEntAccState, action: PayloadAction<string>) {
      state.modal = action.payload;
    },
    closeModal(state: EditEntAccState) {
      state.modal = ModalStates.close;
    },
    // get
    getEntAcc(state: EditEntAccState) {
      state.stateLoading = true;
    },
    getEntAccSucceeded(state: EditEntAccState, action: PayloadAction<EntAccountDocument>) {
      state.id = action.payload._id;
      state.active = action.payload.active || false;
      state.stripeAccountCompleted = action.payload.stripeAccountCompleted || false;
      state.userAllocation.maxNoOfCoaches = action.payload.maxNoOfCoaches || 0;
      state.userAllocation.maxNoOfEndUsers = action.payload.maxNoOfEndUsers || 0;
      state.userAllocation.maxNoOfGroups = action.payload.maxNoOfGroups || 0;
      state.headerTemplate.headerTemplate = action.payload.headerTemplate || ' ';
      state.promotionalContent = action.payload.promotionalContent || [];
      state.sponsorContent = action.payload.sponsorContent || [];
      state.categories = action.payload.categories || [];
      state.skills = action.payload.skills || [];
      state.colors = action.payload.colors || ' ';
      state.websiteFonts.bodyFont = action.payload.bodyFont || ' ';
      state.websiteFonts.headerFont = action.payload.headerFont || ' ';
      state.websiteFonts.isFontDefault = action.payload.isFontDefault || 'true';
      state.generalInformation.email = action.payload?.ownerClientAdmin?.email || action.payload.email || ' ';
      state.generalInformation.name = action.payload.name || ' ';
      state.generalInformation.entUrl = action.payload.entUrl || ' ';
      state.generalInformation.currency = action.payload.currency || 'AUD';
      state.geography.city = action.payload.city || ' ';
      state.geography.country = action.payload.country || ' ';
      state.socialMedia.facebookUrl = action.payload.facebookUrl || ' ';
      state.socialMedia.twitterUrl = action.payload.twitterUrl || ' ';
      state.socialMedia.instagramUrl = action.payload.instagramUrl || ' ';
      state.socialMedia.youtubeUrl = action.payload.youtubeUrl || ' ';
      state.socialMedia.linkedinUrl = action.payload.linkedinUrl || ' ';
      state.webLogoUrl = action.payload.webLogoUrl || '';
      state.entLogoUrl = action.payload.entLogoUrl || '';
      state.landingPageSections = action.payload.landingPageSections || {
        groups: false,
        sessions: false,
        featured: false,
        experts: false,
        articles: false,
        testimonials: false,
      };
      state.headerSections = action.payload.headerSections || {
        groups: false,
        newsfeed: false,
        experts: false,
        articles: false,
      };
      state.landingPageContent = action.payload.landingPageContent || ' ';
      state.articlesLandingPageContent = action.payload.articleLandingPageContent || ' ';
      state.termsAndConditionsPage = action.payload?.termsAndConditionsPage || { link: '' };
      state.privacyPolicyPage = action.payload?.privacyPolicyPage || { link: '' };
      state.groupLandingPageContent = action.payload.groupLandingPageContent || ' ';
      state.contentLandingPageContent = action.payload.contentLandingPageContent || ' ';
      state.expertLandingPageContent = action.payload.expertLandingPageContent || ' ';
      state.sessionLandingPageContent = action.payload.sessionLandingPageContent || ' ';
      state.groupLandingPageContent = action.payload.groupLandingPageContent || ' ';
      state.sessionLandingPageContent = action.payload.sessionLandingPageContent || '';
      state.bankDetails = action.payload.bankDetails || ' ';
      state.charges = action.payload.charges || '';
      state.articles = action.payload.entPinnedProducts?.articles || [];
      state.experts = action.payload.entPinnedProducts?.experts || [];
      state.groups = action.payload.entPinnedProducts?.groups || [];
      state.sessions = action.payload.entPinnedProducts?.sessions?.filter(
        (session) => (session.sessionType !== SessionType.content),
      ) || [];
      state.contentOnlySessions = action.payload.entPinnedProducts?.sessions?.filter(
        (session) => (session.sessionType === SessionType.content),
      ) || [];
      state.landingCtaContent = action.payload.landingCtaContent || {
        title: '',
        body: '',
        buttonText: '',
        buttonLink: '',
        imageUrl: '',
        template: CtaSectionTemplate.TEXT_ONLY,
      };
      state.stateLoading = false;
    },
    getEntAccFailed(state: EditEntAccState) {
      state.stateLoading = false;
    },

    getFilteredSessions(state: EditEntAccState, _action:PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredSessionsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IFiltered<ISessionAvatar>>,
    ) {
      state.filteredSessions = action.payload;
      state.subStateLoading = false;
    },
    getFilteredSessionsFailed(state: EditEntAccState) {
      state.subStateLoading = false;
    },

    getFilteredContentOnlySessions(
      state: EditEntAccState,
      _action:PayloadAction<IPaginateFilterPayload>,
    ) {
      state.subStateLoading = true;
    },
    getFilteredContentOnlySessionsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IFiltered<ISessionAvatar>>,
    ) {
      state.filteredContentOnlySessions = action.payload;
      state.subStateLoading = false;
    },
    getFilteredContentOnlySessionsFailed(state: EditEntAccState) {
      state.subStateLoading = false;
    },

    getFilteredExperts(
      state: EditEntAccState,
      _action:PayloadAction<IPaginateFilterPayload>,
    ) {
      state.subStateLoading = true;
    },
    getFilteredExpertsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IFiltered<IExpertAvatar>>,
    ) {
      state.filteredExperts = action.payload;
      state.subStateLoading = false;
    },
    getFilteredExpertsFailed(state: EditEntAccState) {
      state.subStateLoading = false;
    },

    getFilteredGroups(
      state: EditEntAccState,
      _action:PayloadAction<IPaginateFilterPayload>,
    ) {
      state.subStateLoading = true;
    },
    getFilteredGroupsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IFiltered<IGroupAvatar>>,
    ) {
      state.filteredGroups = action.payload;
      state.subStateLoading = false;
    },
    getFilteredGroupsFailed(state: EditEntAccState) {
      state.subStateLoading = false;
    },

    getFilteredArticles(
      state: EditEntAccState,
      _action:PayloadAction<IPaginateFilterPayload>,
    ) {
      state.subStateLoading = true;
    },
    getFilteredArticlesSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IFiltered<IArticleAvatar>>,
    ) {
      state.filteredArticles = action.payload;
      state.subStateLoading = false;
    },
    getFilteredArticlesFailed(state: EditEntAccState) {
      state.subStateLoading = false;
    },
    getStripConnectUrl(state: EditEntAccState) {
      state.loading = true;
    },
    getStripConnectUrlSucceeded(state: EditEntAccState, action: PayloadAction<string>) {
      state.stripeConnectUrl = action.payload;
      state.loading = false;
    },
    getStripConnectUrlFailed(state: EditEntAccState) {
      state.loading = false;
    },

    // edit
    editUserAllocation(state: EditEntAccState, _action: PayloadAction<IUserAllocationForm>) {
      state.loading = true;
    },
    editUserAllocationSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IUserAllocationForm>,
    ) {
      state.loading = false;
      state.userAllocation = action.payload;
      state.modal = ModalStates.close;
    },
    editUserAllocationFailed(state: EditEntAccState) {
      state.loading = false;
    },

    setId(state: EditEntAccState, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    editHeaderTemplate(state: EditEntAccState, _action: PayloadAction<IHeaderTemplateModalForm>) {
      state.loading = true;
    },
    editHeaderTemplateSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IHeaderTemplateModalForm>,
    ) {
      state.loading = false;
      state.headerTemplate = action.payload;
      state.modal = ModalStates.close;
    },
    editFailed(state: EditEntAccState) {
      state.loading = false;
    },

    editPromotionalContent(
      state: EditEntAccState,
      _action: PayloadAction<IPromotionalContentModalForm>,
    ) {
      state.loading = true;
    },
    editPromotionalContentSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IPromotionalContentModalForm>,
    ) {
      state.promotionalContent[0] = action.payload;
      state.loading = false;
      state.modal = ModalStates.close;
    },

    editSponsorsLogo(state: EditEntAccState, _action: PayloadAction<ISponsorContentModalForm>) {
      state.loading = true;
    },
    editSponsorContentSucceeded(
      state: EditEntAccState,
      action: PayloadAction<ISponsorContentModalForm>,
    ) {
      state.sponsorContent = action.payload.sponsorContent;
      state.loading = false;
      state.modal = ModalStates.close;
    },
    editWebsiteColors(state: EditEntAccState, _action: PayloadAction<IWebsiteColorsModalForm>) {
      state.loading = true;
    },
    editWebsiteColorsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IWebsiteColorsModalForm>,
    ) {
      state.loading = false;
      state.colors = action.payload;
      state.modal = ModalStates.close;
    },
    editWebsiteFonts(state: EditEntAccState, _action: PayloadAction<IWebsiteFontsModalForm>) {
      state.loading = true;
    },

    editWebsiteFontsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IWebsiteFontsModalForm>,
    ) {
      state.loading = false;
      state.websiteFonts = action.payload;

      state.modal = ModalStates.close;
    },

    getSitePreferences(state: EditEntAccState) {
      state.loading = true;
    },
    editGeneralInformation(
      state: EditEntAccState,
      _action: PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = true;
    },
    editGeneralInformationSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = false;
      state.generalInformation = action.payload;
      state.modal = ModalStates.close;
    },

    editGeography(state: EditEntAccState, _action: PayloadAction<IGeographyModalForm>) {
      state.loading = true;
    },
    editGeographySucceeded(state: EditEntAccState, action: PayloadAction<IGeographyModalForm>) {
      state.loading = false;
      state.geography = action.payload;
      state.modal = ModalStates.close;
    },
    editSocialMedia(state: EditEntAccState, _action: PayloadAction<ISocialMediaModalForm>) {
      state.loading = true;
    },
    editSocialMediaSucceeded(state: EditEntAccState, action: PayloadAction<ISocialMediaModalForm>) {
      state.loading = false;
      state.socialMedia = action.payload;
      state.modal = ModalStates.close;
    },
    editCategory(state: EditEntAccState, _action: PayloadAction<ICategoryModalForm>) {
      state.loading = true;
    },
    editCategorySucceeded(state: EditEntAccState, action: PayloadAction<ICategoryModalForm>) {
      state.loading = false;
      state.categories = action.payload.categories;
      state.modal = ModalStates.close;
    },
    editSkill(state: EditEntAccState, _action: PayloadAction<ISkillModalForm>) {
      state.loading = true;
    },
    editSkillSucceeded(state: EditEntAccState, action: PayloadAction<ISkillModalForm>) {
      state.loading = false;
      state.skills = action.payload.skills;
      state.modal = ModalStates.close;
    },

    editWebLogo(state: EditEntAccState, _action: PayloadAction<IWebLogoModalForm>) {
      state.loading = true;
    },
    editWebLogoSucceeded(state: EditEntAccState, action: PayloadAction<IWebLogoModalForm>) {
      state.loading = false;
      state.webLogoUrl = action.payload.webLogoUrl;
      state.entLogoUrl = action.payload.entLogoUrl;
      state.modal = ModalStates.close;
    },

    getGeneralInformation(state: EditEntAccState) {
      state.loading = true;
    },
    editLandingPageSections(
      state: EditEntAccState,
      _action: PayloadAction<ILandingPageSectionsModalForm>,
    ) {
      state.loading = true;
    },
    editLandingPageSectionsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<ILandingPageSectionsModalForm>,
    ) {
      state.loading = false;
      state.landingPageSections = action.payload;
      state.modal = ModalStates.close;
    },
    editHeaderSections(
      state: EditEntAccState,
      _action: PayloadAction<IHeaderSectionsModalForm>,
    ) {
      state.loading = true;
    },
    editHeaderSectionsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IHeaderSectionsModalForm>,
    ) {
      state.loading = false;
      state.headerSections = action.payload;
      state.modal = ModalStates.close;
    },
    editLandingPage(state: EditEntAccState, _action: PayloadAction<ILandingPageModalForm>) {
      state.loading = true;
    },
    editLandingPageSucceeded(state: EditEntAccState, action: PayloadAction<ILandingPageModalForm>) {
      state.loading = false;
      state.landingPageContent = action.payload;
      state.modal = ModalStates.close;
    },
    editGroupLandingPage(
      state: EditEntAccState,
      _action: PayloadAction<IGroupLandingPageModalForm>,
    ) {
      state.loading = true;
    },
    editGroupLandingPageSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IGroupLandingPageModalForm>,
    ) {
      state.loading = false;
      state.groupLandingPageContent = action.payload;
      state.modal = ModalStates.close;
    },
    editExpertLandingPage(
      state: EditEntAccState,
      _action: PayloadAction<IExpertLandingPageModalForm>,
    ) {
      state.loading = true;
    },
    editExpertLandingPageSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IExpertLandingPageModalForm>,
    ) {
      state.loading = false;
      state.expertLandingPageContent = action.payload;
      state.modal = ModalStates.close;
    },
    editSessionLandingPage(
      state: EditEntAccState,
      _action: PayloadAction<ISessionLandingPageModalForm>,
    ) {
      state.loading = true;
    },
    editSessionLandingPageSucceeded(
      state: EditEntAccState,
      action: PayloadAction<ISessionLandingPageModalForm>,
    ) {
      state.loading = false;
      state.sessionLandingPageContent = action.payload;
      state.modal = ModalStates.close;
    },
    editContentLandingPage(
      state: EditEntAccState,
      _action: PayloadAction<IContentLandingPageModalForm>,
    ) {
      state.loading = true;
    },
    editContentLandingPageSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IContentLandingPageModalForm>,
    ) {
      state.loading = false;
      state.contentLandingPageContent = action.payload;
      state.modal = ModalStates.close;
    },

    editArticlesLandingPage(
      state: EditEntAccState,
      _action: PayloadAction<IArticlesLandingPageModalForm>,
    ) {
      state.loading = true;
    },
    editArticlesLandingPageSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IArticlesLandingPageModalForm>,
    ) {
      state.loading = false;
      state.articlesLandingPageContent = action.payload;
      state.modal = ModalStates.close;
    },
    editTermsConditonsPage(
      state: EditEntAccState,
      _action: PayloadAction<ITermsAndConditionsPageModalForm>,
    ) {
      state.loading = true;
    },
    editTermsConditionsPageSucceeded(
      state: EditEntAccState,
      action: PayloadAction<ITermsAndConditionsPageModalForm>,
    ) {
      state.loading = false;
      state.termsAndConditionsPage = action.payload;
      state.modal = ModalStates.close;
    },
    editPrivacyPolicyPage(
      state: EditEntAccState,
      _action: PayloadAction<ITermsAndConditionsPageModalForm>,
    ) {
      state.loading = true;
    },
    editPrivacyPolicyPageSucceeded(
      state: EditEntAccState,
      action: PayloadAction<ITermsAndConditionsPageModalForm>,
    ) {
      state.loading = false;
      state.privacyPolicyPage = action.payload;
      state.modal = ModalStates.close;
    },
    editCommunicationPreferences(
      state: EditEntAccState,
      _action: PayloadAction<ICommunicationPreferencesModalForm>,
    ) {
      state.loading = true;
    },
    editCommunicationPreferencesSucceeded(
      state: EditEntAccState,
      action: PayloadAction<ICommunicationPreferencesModalForm>,
    ) {
      state.loading = false;
      state.communicationPreferences = action.payload;
      state.modal = ModalStates.close;
    },
    editSessionsLandingPage(
      state: EditEntAccState,
      _action: PayloadAction<ISessionsLandingPageModalForm>,
    ) {
      state.loading = true;
    },
    editSessionsLandingPageSucceeded(
      state: EditEntAccState,
      action: PayloadAction<ISessionsLandingPageModalForm>,
    ) {
      state.loading = false;
      state.sessionLandingPageContent = action.payload;
      state.modal = ModalStates.close;
    },
    editCharges(state: EditEntAccState, _action: PayloadAction<IChargesModalForm>) {
      state.loading = true;
    },
    editChargesSucceeded(state: EditEntAccState, action: PayloadAction<IChargesModalForm>) {
      state.loading = false;
      state.charges = action.payload;
      state.modal = ModalStates.close;
    },
    editChargesFailed(state: EditEntAccState) {
      state.loading = false;
    },

    editBankDetails(state: EditEntAccState, _action: PayloadAction<IBankDetailsModalForm>) {
      state.loading = true;
    },
    editBankDetailsSucceeded(state: EditEntAccState, action: PayloadAction<IBankDetailsModalForm>) {
      state.loading = false;
      state.bankDetails = action.payload;
      state.modal = ModalStates.close;
    },
    editBankDetailsFailed(state: EditEntAccState) {
      state.loading = false;
    },
    editActive(state: EditEntAccState, _action: PayloadAction<{ active: boolean }>) {
      state.loading = true;
    },
    editActiveSucceeded(state: EditEntAccState, action: PayloadAction<boolean>) {
      state.loading = false;
      state.active = action.payload;
      state.modal = ModalStates.close;
    },
    editActiveFailed(state: EditEntAccState) {
      state.loading = false;
    },
    editSessions(
      state: EditEntAccState,
      _action: PayloadAction<IEditProduct<ISessionAvatarWithAction>>,
    ) {
      state.loading = true;
    },
    editSessionsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IEditProduct<ISessionAvatarWithAction>>,
    ) {
      if (!action.payload.reOrder) {
        state.sessions = action.payload.product.map((sessionWithAction) => {
          const { isAdded, ...session } = sessionWithAction; return session;
        });
        state.modal = ModalStates.close;
        state.loading = false;
      }
    },
    editSessionsFailed(state: EditEntAccState) {
      state.loading = false;
    },

    editContentOnlySessions(
      state: EditEntAccState,
      _action: PayloadAction<IEditProduct<ISessionAvatarWithAction>>,
    ) {
      state.loading = true;
    },
    editContentOnlySessionsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IEditProduct<ISessionAvatarWithAction>>,
    ) {
      if (!action.payload.reOrder) {
        state.contentOnlySessions = action.payload.product.map((sessionWithAction) => {
          const { isAdded, ...session } = sessionWithAction; return session;
        });
        state.modal = ModalStates.close;
        state.loading = false;
      }
    },
    editContentOnlySessionsFailed(state: EditEntAccState) {
      state.loading = false;
    },

    editExperts(
      state: EditEntAccState,
      _action: PayloadAction<IEditProduct<IExpertAvatarWithAction>>,
    ) {
      state.loading = true;
    },
    editExpertsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IEditProduct<IExpertAvatarWithAction>>,
    ) {
      if (!action.payload.reOrder) {
        state.experts = action.payload.product.map((expertWithAction) => {
          const { isAdded, ...expert } = expertWithAction; return expert;
        });
        state.modal = ModalStates.close;
        state.loading = false;
      }
    },
    editExpertsFailed(state: EditEntAccState) {
      state.loading = false;
    },
    editGroups(
      state: EditEntAccState,
      _action: PayloadAction<IEditProduct<IGroupAvatarWithAction>>,
    ) {
      state.loading = true;
    },
    editGroupsSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IEditProduct<IGroupAvatarWithAction>>,
    ) {
      if (!action.payload.reOrder) {
        state.groups = action.payload.product.map((groupWithAction) => {
          const { isAdded, ...group } = groupWithAction; return group;
        });
        state.modal = ModalStates.close;
        state.loading = false;
      }
    },
    editGroupsFailed(state: EditEntAccState) {
      state.loading = false;
    },

    editArticles(
      state: EditEntAccState,
      _action: PayloadAction<IEditProduct<IArticleAvatarWithAction>>,
    ) {
      state.loading = true;
    },
    editArticlesSucceeded(
      state: EditEntAccState,
      action: PayloadAction<IEditProduct<IArticleAvatarWithAction>>,
    ) {
      if (!action.payload.reOrder) {
        state.articles = action.payload.product.map((articleWithAction) => {
          const { isAdded, ...article } = articleWithAction; return article;
        });
        state.modal = ModalStates.close;
        state.loading = false;
      }
    },
    editArticlesFailed(state: EditEntAccState) {
      state.loading = false;
    },

    // delete
    deleteGiftCard(state: EditEntAccState) {
      state.loading = true;
    },
    deleteGiftCardSucceeded(state: EditEntAccState) {
      state.loading = false;
      state.modal = ModalStates.close;
    },
    deleteGiftCardFailed(state: EditEntAccState) {
      state.loading = false;
    },

    deleteEntAcc(state: EditEntAccState) {
      state.loading = true;
    },
    deleteEntAccSucceeded(state: EditEntAccState) {
      state.loading = false;
      state.modal = ModalStates.close;
    },
    deleteEntAccFailed(state: EditEntAccState) {
      state.loading = false;
    },
    getCategoriesSucceeded(state: EditEntAccState, action: PayloadAction<ICategory[]>) {
      state.categories = action.payload;
    },
    editLandingCtaContent(
      state: EditEntAccState,
      _action: PayloadAction<ILandingCtaContent>,
    ) {
      state.loading = true;
    },
    editLandingCtaContentSucceeded(
      state: EditEntAccState,
      action: PayloadAction<ILandingCtaContent>,
    ) {
      state.loading = false;
      state.landingCtaContent = action.payload;
      state.modal = ModalStates.close;
    },
  },
});

export const { actions: editEntAccActions } = editEntAccSlice;
