import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { selectIsSlocoachSuperUser } from 'base/auth/selectors';
import { SessionType, SessionAudienceTypes, ENVIRONMENT } from 'config';
import API from './constants';
import {
  selectContentOnlySessions, selectGeneralInformation, selectId, selectSessions,
} from './selector';

import { editEntAccActions } from './slice';

import {
  ISessionAvatar, ISessionAvatarWithAction,
} from './components/experts/components/edit-expert/components/sessions/interface';
import { IGroupAvatarWithAction } from './components/groups/interface';
import {
  IActive,
  IArticlesLandingPageModalForm,
  IBankDetailsModalForm,
  ICategoryModalForm,
  IChargesModalForm,
  IContentLandingPageModalForm,
  IEditProduct,
  IExpertLandingPageModalForm,
  IGeneralInformationModalForm,
  IGeographyModalForm,
  IGroupLandingPageModalForm,
  IHeaderSectionsModalForm,
  IHeaderTemplateModalForm,
  ILandingCtaContent,
  ILandingPageModalForm,
  ILandingPageSectionsModalForm,
  IPromotionalContentModalForm,
  ISessionLandingPageModalForm,
  ISessionsLandingPageModalForm,
  ISkillModalForm,
  ISocialMediaModalForm,
  ISponsorContentModalForm,
  ITermsAndConditionsPageModalForm,
  IUserAllocationForm,
  IWebLogoModalForm,
  IWebsiteColorsModalForm,
  IWebsiteFontsModalForm,
  ICommunicationPreferencesModalForm,
} from './interface';
import { IExpertAvatarWithAction } from './components/experts/interface';
import { IArticleAvatarWithAction } from './components/articles/interface';
import { IPaginateFilterPayload } from './components/experts/components/view-experts/interface';

// get
export function* getEntAccGenerator(): any {
  try {
    const id = yield select(selectId);
    const isSlcocoachSuperUser = yield select(selectIsSlocoachSuperUser);
    const response: IRestApiResponse = yield call(
      request,
      isSlcocoachSuperUser ? { path: `${API.GET_ENT_ACC.path}/${id}`, method: API.GET_ENT_ACC.method } : API.GET_ENT_ACC,
      null,
      true,
    );
    if (response.statusCode === 200) {
      const categories: IRestApiResponse = yield call(
        request,
        API.GET_CATEGORIES,
        null,
        true,
        false,
        ENVIRONMENT.BACKEND_API,
        id,
      );
      yield put(editEntAccActions.getEntAccSucceeded(response.data));
      yield put(editEntAccActions.getCategoriesSucceeded(categories.data));
    } else {
      yield put(editEntAccActions.getEntAccFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.getEntAccFailed());
  }
}

export function* getFilteredSessionsGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_SESSIONS_BY_ENT.path}/${id}?publicOnly=true&type=${[SessionType.liveClass, SessionType.course, SessionType.digitalSession, SessionType.liveSession]}&keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&audienceType=${SessionAudienceTypes.public}&status=${payload?.status || 'All'}&withoutDeleted=${payload.withoutDeleted || 'true'}`, method: API.GET_SESSIONS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editEntAccActions.getFilteredSessionsSucceeded(response.data));
    } else {
      yield put(editEntAccActions.getFilteredSessionsFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.getFilteredSessionsFailed());
  }
}

export function* getFilteredContentOnlySessionsGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_SESSIONS_BY_ENT.path}/${id}?publicOnly=true&type=${SessionType.content}&keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&audienceType=${SessionAudienceTypes.public}&status=${payload?.status || 'All'}&withoutDeleted=${payload.withoutDeleted || 'true'}`, method: API.GET_SESSIONS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editEntAccActions.getFilteredContentOnlySessionsSucceeded(response.data));
    } else {
      yield put(editEntAccActions.getFilteredContentOnlySessionsFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.getFilteredContentOnlySessionsFailed());
  }
}

export function* getFilteredExpertsGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_EXPERTS_BY_ENT.path}/${id}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&status=${payload?.status || 'All'}&withDeleted=${!payload.withoutDeleted || 'false'}`, method: API.GET_EXPERTS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editEntAccActions.getFilteredExpertsSucceeded(response.data));
    } else {
      yield put(editEntAccActions.getFilteredExpertsFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.getFilteredExpertsFailed());
  }
}

export function* getFilteredGroupsGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_GROUPS_BY_ENT.path}/${id}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&visibility=${payload?.visibility}`, method: API.GET_GROUPS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editEntAccActions.getFilteredGroupsSucceeded(response.data));
    } else {
      yield put(editEntAccActions.getFilteredGroupsFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.getFilteredGroupsFailed());
  }
}

export function* getFilteredArticlesGenerator({ payload }:
PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_ARTICLES_BY_ENT.path}/${id}?publicOnly=true&keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}`, method: API.GET_ARTICLES_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editEntAccActions.getFilteredArticlesSucceeded(response.data));
    } else {
      yield put(editEntAccActions.getFilteredArticlesFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.getFilteredArticlesFailed());
  }
}

export function* getStripConnectUrlGenerator(): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_STRIPE_CONNECT_URL,
      { returnRoute: 'payments' },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.getStripConnectUrlSucceeded(response.data.url));
    } else {
      yield put(editEntAccActions.getStripConnectUrlFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.getStripConnectUrlFailed());
  }
}
// edit
export function* editGeneralInformationGenerator({
  payload,
}: PayloadAction<IGeneralInformationModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editGeneralInformationSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}
export function* editGeographyGenerator({ payload }:
PayloadAction<IGeographyModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editGeographySucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editSocialMediaGenerator({ payload }: PayloadAction<ISocialMediaModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editSocialMediaSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editCategoryGenerator({ payload }: PayloadAction<ICategoryModalForm>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_CATEGORIES,
      { data: payload.categories.map((i) => ({ id: i.id, name: i.name })) },
      true,
    );
    if (response.statusCode === 201) {
      const categories: IRestApiResponse = yield call(
        request,
        API.GET_CATEGORIES,
        null,
        true,
      );
      yield put(editEntAccActions.editCategorySucceeded({ categories: categories.data }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editSkillGenerator({ payload }: PayloadAction<ISkillModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { skills: [...payload.skills.map((skill) => { if (skill.id === '') return ({ name: skill.name }); return skill; })], id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editSkillSucceeded({ skills: response.data?.skills }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editWebLogoGenerator({ payload }: PayloadAction<IWebLogoModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      {
        ...payload,
        id,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editWebLogoSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}
export function* editHeaderTemplateGenerator({
  payload,
}: PayloadAction<IHeaderTemplateModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editHeaderTemplateSucceeded(payload));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editPromotionalContentGenerator({
  payload,
}: PayloadAction<IPromotionalContentModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { promotionalContent: [{ ...payload }], id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editPromotionalContentSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}
export function* editSponsorsLogoGenerator({ payload }:
PayloadAction<ISponsorContentModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editSponsorContentSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}
export function* editHWebsiteColorsGenerator({ payload }:
PayloadAction<IWebsiteColorsModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { colors: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editWebsiteColorsSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editWebsiteFontsGenerator({ payload }:
PayloadAction<IWebsiteFontsModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editWebsiteFontsSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editUserAllocationGenerator({ payload }: PayloadAction<IUserAllocationForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editUserAllocationSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editUserAllocationFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editUserAllocationFailed());
  }
}

export function* deleteGiftCardGenerator(): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(request, API.DELETE_GIFT_CARD, { id }, true);
    if (response.statusCode === 201) {
      yield put(editEntAccActions.deleteGiftCardSucceeded());
    } else {
      yield put(editEntAccActions.deleteGiftCardFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.deleteGiftCardFailed());
  }
}

export function* editChargesGenerator({ payload }: PayloadAction<IChargesModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { charges: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editChargesSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editChargesFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editChargesFailed());
  }
}
export function* editBankDetailsGenerator({ payload }: PayloadAction<IBankDetailsModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { bankDetails: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editBankDetailsSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editBankDetailsFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editBankDetailsFailed());
  }
}

export function* editLandingPageSectionsGenerator({ payload }
:PayloadAction<ILandingPageSectionsModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { landingPageSections: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editLandingPageSectionsSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editHeaderSectionsGenerator({
  payload,
}: PayloadAction<IHeaderSectionsModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { headerSections: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editHeaderSectionsSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editLandingPageGenerator({ payload }: PayloadAction<ILandingPageModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { landingPageContent: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editLandingPageSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editExpertLandingPageGenerator({
  payload,
}: PayloadAction<IExpertLandingPageModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { expertLandingPageContent: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editExpertLandingPageSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editSessionLandingPageGenerator({ payload }
:PayloadAction<ISessionLandingPageModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { sessionLandingPageContent: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editSessionLandingPageSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editGroupLandingPageGenerator({ payload }
:PayloadAction<IGroupLandingPageModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { groupLandingPageContent: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editGroupLandingPageSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editContentLandingPageGenerator({
  payload,
}: PayloadAction<IContentLandingPageModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { contentLandingPageContent: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editContentLandingPageSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editTermsConditonsPageGenerator({
  payload,
}: PayloadAction<ITermsAndConditionsPageModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { termsAndConditionsPage: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editTermsConditionsPageSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editPrivacyPolicyPageGenerator({
  payload,
}: PayloadAction<ITermsAndConditionsPageModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { privacyPolicyPage: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editPrivacyPolicyPageSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editCommunicationPreferencesGenerator({
  payload,
}: PayloadAction<ICommunicationPreferencesModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { communicationPreferences: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editCommunicationPreferencesSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editArticlesLandingPageGenerator({
  payload,
}: PayloadAction<IArticlesLandingPageModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { articleLandingPageContent: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editArticlesLandingPageSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editSessionsLandingPageGenerator({ payload }
:PayloadAction<ISessionsLandingPageModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { sessionLandingPageContent: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editSessionsLandingPageSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editActiveGenerator({ payload }:PayloadAction<IActive>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { ...payload, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editActiveSucceeded(payload.active));
    } else {
      yield put(editEntAccActions.editActiveFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editActiveFailed());
  }
}
export function* editSessionsGenerator({ payload }:
PayloadAction<IEditProduct<ISessionAvatarWithAction>>): any {
  try {
    const id = yield select(selectId);
    const contentOnlySessions:ISessionAvatar[] = yield select(selectContentOnlySessions);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.POST_ENT_PRODUCTS.path}/${id}`, method: API.POST_ENT_PRODUCTS.method },
      {
        sessions: [...(contentOnlySessions.map((session) => (session.id))),
          ...payload.product.map((session) => (session.id))],
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editSessionsSucceeded(payload));
    } else {
      yield put(editEntAccActions.editSessionsFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editSessionsFailed());
  }
}
export function* editContentOnlySessionsGenerator({ payload }:
PayloadAction<IEditProduct<ISessionAvatarWithAction>>): any {
  try {
    const id = yield select(selectId);
    const sessions:ISessionAvatar[] = yield select(selectSessions);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.POST_ENT_PRODUCTS.path}/${id}`, method: API.POST_ENT_PRODUCTS.method },
      {
        sessions: [...(sessions.map((session) => (session.id))),
          ...payload.product.map((session) => (session.id))],
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editContentOnlySessionsSucceeded(payload));
    } else {
      yield put(editEntAccActions.editContentOnlySessionsFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editContentOnlySessionsFailed());
  }
}

export function* editExpertsGenerator({ payload }:
PayloadAction<IEditProduct<IExpertAvatarWithAction>>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.POST_ENT_PRODUCTS.path}/${id}`, method: API.POST_ENT_PRODUCTS.method },
      { experts: payload.product.map((expert) => (expert.id)) },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editExpertsSucceeded(payload));
    } else {
      yield put(editEntAccActions.editExpertsFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editExpertsFailed());
  }
}

export function* editGroupsGenerator({ payload }:
PayloadAction<IEditProduct<IGroupAvatarWithAction>>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.POST_ENT_PRODUCTS.path}/${id}`, method: API.POST_ENT_PRODUCTS.method },
      { groups: payload.product.map((group) => (group._id)) },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editGroupsSucceeded(payload));
    } else {
      yield put(editEntAccActions.editGroupsFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editGroupsFailed());
  }
}

export function* editArticlesGenerator({ payload }:
PayloadAction<IEditProduct<IArticleAvatarWithAction>>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.POST_ENT_PRODUCTS.path}/${id}`, method: API.POST_ENT_PRODUCTS.method },
      { articles: payload.product.map((article) => (article._id)) },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editArticlesSucceeded(payload));
    } else {
      yield put(editEntAccActions.editArticlesFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editArticlesFailed());
  }
}

// delete
export function* deleteEntAccGenerator(): any {
  try {
    const id = yield select(selectId);
    const { email } = yield select(selectGeneralInformation);
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_ENT_ACC,
      { id, email },
      true,
    );
    if (response.statusCode === 200) {
      yield put(editEntAccActions.deleteEntAccSucceeded());
      window.location.pathname = '/ent-account';
    } else {
      yield put(editEntAccActions.deleteEntAccFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.deleteEntAccFailed());
  }
}

export function* editLandingCtaContentGenerator({
  payload,
}: PayloadAction<ILandingCtaContent>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      { landingCtaContent: { ...payload }, id },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editEntAccActions.editLandingCtaContentSucceeded({ ...payload }));
    } else {
      yield put(editEntAccActions.editFailed());
    }
  } catch (error) {
    yield put(editEntAccActions.editFailed());
  }
}

export function* editEntAccSaga() {
  yield all([
    // get
    takeLatest(editEntAccActions.getEntAcc.type, getEntAccGenerator),
    takeLatest(editEntAccActions.getFilteredSessions.type, getFilteredSessionsGenerator),
    takeLatest(
      editEntAccActions.getFilteredContentOnlySessions.type,
      getFilteredContentOnlySessionsGenerator,
    ),

    takeLatest(editEntAccActions.getFilteredExperts.type, getFilteredExpertsGenerator),
    takeLatest(editEntAccActions.getFilteredGroups.type, getFilteredGroupsGenerator),
    takeLatest(editEntAccActions.getFilteredArticles.type, getFilteredArticlesGenerator),
    takeLatest(editEntAccActions.getStripConnectUrl.type, getStripConnectUrlGenerator),
    // edit
    takeLatest(editEntAccActions.editGeneralInformation.type, editGeneralInformationGenerator),
    takeLatest(editEntAccActions.editGeography.type, editGeographyGenerator),
    takeLatest(editEntAccActions.editSocialMedia.type, editSocialMediaGenerator),
    takeLatest(editEntAccActions.editCategory.type, editCategoryGenerator),
    takeLatest(editEntAccActions.editSkill.type, editSkillGenerator),
    takeLatest(editEntAccActions.editWebLogo.type, editWebLogoGenerator),
    takeLatest(editEntAccActions.editUserAllocation.type, editUserAllocationGenerator),
    takeLatest(editEntAccActions.editCharges.type, editChargesGenerator),
    takeLatest(editEntAccActions.editBankDetails.type, editBankDetailsGenerator),
    takeLatest(editEntAccActions.editArticlesLandingPage.type, editArticlesLandingPageGenerator),
    takeLatest(editEntAccActions.editTermsConditonsPage.type, editTermsConditonsPageGenerator),
    takeLatest(editEntAccActions.editPrivacyPolicyPage.type, editPrivacyPolicyPageGenerator),
    takeLatest(
      editEntAccActions.editCommunicationPreferences.type,
      editCommunicationPreferencesGenerator,
    ),
    takeLatest(editEntAccActions.editContentLandingPage.type, editContentLandingPageGenerator),
    takeLatest(editEntAccActions.editExpertLandingPage.type, editExpertLandingPageGenerator),
    takeLatest(editEntAccActions.editSessionLandingPage.type, editSessionLandingPageGenerator),
    takeLatest(editEntAccActions.editGroupLandingPage.type, editGroupLandingPageGenerator),
    takeLatest(editEntAccActions.editSessionsLandingPage.type, editSessionsLandingPageGenerator),
    takeLatest(editEntAccActions.editLandingPage.type, editLandingPageGenerator),
    takeLatest(editEntAccActions.editLandingPageSections.type, editLandingPageSectionsGenerator),
    takeLatest(editEntAccActions.editHeaderSections.type, editHeaderSectionsGenerator),
    takeLatest(editEntAccActions.editHeaderTemplate.type, editHeaderTemplateGenerator),
    takeLatest(editEntAccActions.editPromotionalContent.type, editPromotionalContentGenerator),
    takeLatest(editEntAccActions.editSponsorsLogo.type, editSponsorsLogoGenerator),
    takeLatest(editEntAccActions.editWebsiteColors.type, editHWebsiteColorsGenerator),
    takeLatest(editEntAccActions.editWebsiteFonts.type, editWebsiteFontsGenerator),
    takeLatest(editEntAccActions.editActive.type, editActiveGenerator),
    takeLatest(editEntAccActions.editSessions.type, editSessionsGenerator),
    takeLatest(editEntAccActions.editContentOnlySessions.type, editContentOnlySessionsGenerator),
    takeLatest(editEntAccActions.editExperts.type, editExpertsGenerator),
    takeLatest(editEntAccActions.editGroups.type, editGroupsGenerator),
    takeLatest(editEntAccActions.editArticles.type, editArticlesGenerator),
    // delete
    takeLatest(editEntAccActions.deleteEntAcc.type, deleteEntAccGenerator),
    takeLatest(editEntAccActions.deleteGiftCard.type, deleteGiftCardGenerator),
    takeLatest(editEntAccActions.editLandingCtaContent.type, editLandingCtaContentGenerator),
  ]);
}

export default editEntAccSaga;
