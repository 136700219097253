/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupTypes, GroupVisibilities } from 'config';
import { ICreateContentModalForm } from '../../../experts/components/edit-expert/components/content/interface';
import {
  GroupArticleDocument,
  GroupContentDocument,
  GroupDocument,
  GroupSessionDocument,
  IAccountTypeModalForm,
  IArticleItemData,
  IExpertGroupDetails,
  IExpertItemData,
  IFilteredArticles,
  IFilteredExperts,
  IFilteredSessions,
  IFilteredUsers,
  IGeneralInformationModalForm,
  IOpenModal,
  ISessionItemData,
  ISponsorContentModalForm,
  IUserRequestPayload,
  ModalStates,
} from '../../interface';
import { EditGroupState, IPaginateFilterPayload } from './interface';

export const initialState: EditGroupState = {
  stateLoading: false,
  loading: false,
  subStateLoading: false,
  isActive: false,
  deleted: false,
  pendingRequests: 0,
  id: '',
  entId: '',
  modalState: '',
  generalInformation: {
    title: '',
    subTitle: '',
    description: '',
    bannerImgUrl: '',
  },
  accountType: {
    groupType: GroupTypes.public,
    groupVisibility: GroupVisibilities.public,
    password: '',
    currency: 'AUD',
    subscriptionFee: 0,
    subscriptionFrequency: '',
  },
  sponsorContent: [{ contentUrl: '', url: '' }],
  experts: { result: [], total: 0 },
  sessions: { result: [], total: 0 },
  content: { result: [], total: 0 },
  articles: { result: [], total: 0 },
  users: { result: [], total: 0 },

  expertIds: [],
  sessionIds: [],
  contentIds: [],
  articleIds: [],

  filteredExperts: { result: [], total: 0 },
  filteredSessions: { result: [], total: 0 },
  filteredArticles: { result: [], total: 0 },
  filteredContent: { result: [], total: 0 },
  requests: [],
};

export const editGroupSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/group/edit-group',
  initialState,
  reducers: {
    openModal(state: EditGroupState, action: PayloadAction<string>) {
      state.modalState = action.payload;
    },
    closeModal(state: EditGroupState) {
      state.modalState = ModalStates.close;
    },
    setId(state: EditGroupState, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    setEntId(state: EditGroupState, action: PayloadAction<string>) {
      state.entId = action.payload;
    },
    getGroup(state: EditGroupState) {
      state.stateLoading = true;
    },
    getGroupSucceeded(state: EditGroupState, action: PayloadAction<GroupDocument>) {
      const group = action.payload;
      state.stateLoading = false;
      state.loading = false;
      state.isActive = group.isActive;
      state.deleted = group.deleted;
      state.accountType.groupType = group.groupType || 'public';
      state.accountType.groupVisibility = group.groupVisibility || 'public';
      state.accountType.password = group.password || '';
      state.accountType.currency = group.currency || 'AUD';
      state.accountType.subscriptionFee = +group.subscriptionFee || 0;
      state.accountType.subscriptionFrequency = group.subscriptionFrequency || '';
      state.generalInformation.title = group.title || '';
      state.generalInformation.subTitle = group.subTitle || '';
      state.generalInformation.description = group.description || '';
      state.generalInformation.bannerImgUrl = group.bannerImgUrl || '';
      state.sponsorContent = group.sponsorContent || [];
      state.pendingRequests = group?.pendingRequests || 0;

      const experts: string[] = [];

      let articles: GroupArticleDocument[] = [];
      let sessions: GroupSessionDocument[] = [];
      let contents: GroupContentDocument[] = [];

      group?.experts?.forEach((details: IExpertGroupDetails) => {
        experts.push(details.expert);
        if (details?.articles) {
          articles = [...articles, ...details.articles];
        }
        if (details?.contents) {
          contents = [...contents, ...details.contents];
        }
        if (details?.sessions) {
          sessions = [...sessions, ...details.sessions];
        }
      });

      state.expertIds = experts;
      state.articleIds = articles;
      state.contentIds = contents;
      state.sessionIds = sessions;
    },
    getGroupFailed(state: EditGroupState) {
      state.stateLoading = false;
    },
    // get sessions
    getSessions(state: EditGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getSessionsSucceeded(state: EditGroupState, action: PayloadAction<IFilteredSessions>) {
      state.sessions = action.payload;
      state.subStateLoading = false;
    },
    getSessionsFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // get content
    getContent(state: EditGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getContentSucceeded(state: EditGroupState, action: PayloadAction<IFilteredSessions>) {
      state.content = action.payload;
      state.subStateLoading = false;
    },
    getContentFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // get articles
    getArticles(state: EditGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getArticlesSucceeded(state: EditGroupState, action: PayloadAction<IFilteredArticles>) {
      state.articles = action.payload;
      state.subStateLoading = false;
    },
    getArticlesFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // get experts
    getExperts(state: EditGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getExpertsSucceeded(state: EditGroupState, action: PayloadAction<IFilteredExperts>) {
      state.experts = action.payload;
      state.subStateLoading = false;
    },
    getExpertsFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // get filtered experts
    getFilteredExperts(state: EditGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredExpertsSucceeded(state: EditGroupState, action: PayloadAction<IFilteredExperts>) {
      state.filteredExperts = action.payload;
      state.subStateLoading = false;
    },
    getFilteredExpertsFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // get filtered sessions
    getFilteredSessions(state: EditGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredSessionsSucceeded(state: EditGroupState, action: PayloadAction<IFilteredSessions>) {
      state.filteredSessions = action.payload;
      state.subStateLoading = false;
    },
    getFilteredSessionsFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // get filtered content
    getFilteredContent(state: EditGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredContentSucceeded(state: EditGroupState, action: PayloadAction<IFilteredSessions>) {
      state.filteredContent = action.payload;
      state.subStateLoading = false;
    },
    getFilteredContentFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // get filtered articles
    getFilteredArticles(state: EditGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getFilteredArticlesSucceeded(state: EditGroupState, action: PayloadAction<IFilteredArticles>) {
      state.filteredArticles = action.payload;
      state.subStateLoading = false;
    },
    getFilteredArticlesFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // create media
    createMedia(state: EditGroupState, _action: PayloadAction<ICreateContentModalForm>) {
      state.subStateLoading = true;
    },
    createMediaSucceeded(state: EditGroupState, action: PayloadAction<GroupDocument>) {
      const group = action.payload;
      state.subStateLoading = false;
    },
    createMediaFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // edit active
    editActive(state: EditGroupState, _action: PayloadAction<{ isActive: boolean }>) {
      state.loading = true;
    },
    editActiveSucceeded(state: EditGroupState, action: PayloadAction<boolean>) {
      state.loading = false;
      state.isActive = action.payload;
      state.modalState = ModalStates.close;
    },
    editActiveFailed(state: EditGroupState) {
      state.loading = false;
    },
    // edit general information
    editGeneralInformation(
      state: EditGroupState,
      _action: PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = true;
    },
    editGeneralInformationSucceeded(
      state: EditGroupState,
      action: PayloadAction<IGeneralInformationModalForm>,
    ) {
      state.loading = false;
      state.generalInformation = action.payload;
      state.modalState = ModalStates.close;
    },

    editFailed(state: EditGroupState) {
      state.loading = false;
    },
    // edit account type
    editAccountType(state: EditGroupState, _action: PayloadAction<IAccountTypeModalForm>) {
      state.loading = true;
    },
    editAccountTypeSucceeded(state: EditGroupState, action: PayloadAction<IAccountTypeModalForm>) {
      state.loading = false;
      state.accountType = action.payload;
      state.modalState = ModalStates.close;
    },

    // group user request
    handleGroupUserRequest(state: EditGroupState, _action: PayloadAction<IUserRequestPayload>) {
      state.loading = true;
    },

    handleGroupUserRequestSucceeded(
      state: EditGroupState,
      action: PayloadAction<IUserRequestPayload>,
    ) {
      const temp = state.users.result;
      const index = state.users.result.findIndex((user) => user.email === action.payload.email);
      temp[index].modalState = ModalStates.close;
      state.users.result = [...temp];
      state.loading = false;
    },

    handleGroupUserRequestFailed(state: EditGroupState) {
      state.loading = false;
    },

    // edit sponser content
    editSponsorContent(state: EditGroupState, _action: PayloadAction<ISponsorContentModalForm>) {
      state.loading = true;
    },
    editSponsorContentSucceeded(
      state: EditGroupState,
      action: PayloadAction<ISponsorContentModalForm>,
    ) {
      state.sponsorContent = action.payload.sponsorContent;
      state.loading = false;
      state.modalState = ModalStates.close;
    },

    editExperts(state: EditGroupState, _action: PayloadAction<IExpertItemData[]>) {
      state.loading = true;
    },

    editSessions(state: EditGroupState, _action: PayloadAction<ISessionItemData[]>) {
      state.loading = true;
    },
    editArticles(state: EditGroupState, _action: PayloadAction<IArticleItemData[]>) {
      state.loading = true;
    },
    editContent(state: EditGroupState, _action: PayloadAction<ISessionItemData[]>) {
      state.loading = true;
    },
    editUsers(state: EditGroupState, _action: PayloadAction<string[]>) {
      state.loading = true;
    },
    openExpertTableModal(state: EditGroupState, action: PayloadAction<IOpenModal>) {
      const temp = state.experts.result;
      temp.forEach((expert) => {
        if (expert.id === action.payload.id) {
          expert.modalState = action.payload.state;
        }
      });
      state.experts.result = [...temp];
    },
    closeExpertTableModal(state: EditGroupState, action: PayloadAction<string>) {
      const temp = state.experts.result;
      temp.forEach((expert) => {
        if (expert.id === action.payload) {
          expert.modalState = ModalStates.close;
        }
      });
      state.experts.result = [...temp];
    },

    openUserTableModal(state: EditGroupState, action: PayloadAction<IOpenModal>) {
      const temp = state.users.result;
      temp.forEach((user) => {
        if (user.id === action.payload.id) {
          user.modalState = action.payload.state;
        }
      });
      state.users.result = [...temp];
    },
    closeUserTableModal(state: EditGroupState, action: PayloadAction<string>) {
      const temp = state.users.result;
      temp.forEach((user) => {
        if (user.id === action.payload) {
          user.modalState = ModalStates.close;
        }
      });
      state.users.result = [...temp];
    },
    openSessionTableModal(state: EditGroupState, action: PayloadAction<IOpenModal>) {
      const temp = state.sessions.result;
      temp.forEach((session) => {
        if (session.id === action.payload.id) {
          session.modalState = action.payload.state;
        }
      });
      state.sessions.result = [...temp];
    },
    closeSessionTableModal(state: EditGroupState, action: PayloadAction<string>) {
      const temp = state.sessions.result;
      temp.forEach((session) => {
        if (session.id === action.payload) {
          session.modalState = ModalStates.close;
        }
      });
      state.sessions.result = [...temp];
    },
    openContentTableModal(state: EditGroupState, action: PayloadAction<IOpenModal>) {
      const temp = state.content.result;
      temp.forEach((contentItem) => {
        if (contentItem.id === action.payload.id) {
          contentItem.modalState = action.payload.state;
        }
      });
      state.content.result = [...temp];
    },
    closeContentTableModal(state: EditGroupState, action: PayloadAction<string>) {
      const temp = state.content.result;
      temp.forEach((contentItem) => {
        if (contentItem.id === action.payload) {
          contentItem.modalState = ModalStates.close;
        }
      });
      state.content.result = [...temp];
    },
    openArticleTableModal(state: EditGroupState, action: PayloadAction<IOpenModal>) {
      const temp = state.articles.result;
      temp.forEach((article) => {
        if (article._id === action.payload.id) {
          article.modalState = action.payload.state;
        }
      });
      state.articles.result = [...temp];
    },
    closeArticleTableModal(state: EditGroupState, action: PayloadAction<string>) {
      const temp = state.articles.result;
      temp.forEach((article) => {
        if (article._id === action.payload) {
          article.modalState = ModalStates.close;
        }
      });
      state.articles.result = [...temp];
    },
    deleteGroup(state: EditGroupState) {
      state.loading = true;
    },
    deleteGroupSucceeded(state: EditGroupState) {
      state.loading = false;
      state.modalState = ModalStates.close;
    },
    deleteGroupFailed(state: EditGroupState) {
      state.loading = false;
    },
    // delete expert
    deleteExpert(state: EditGroupState, _action: PayloadAction<string>) {
      state.loading = true;
    },
    deleteExpertSucceeded(state: EditGroupState, action: PayloadAction<string>) {
      const temp = state.experts.result.filter((expertItem) => expertItem.id !== action.payload);
      const tempIds = state.expertIds.filter((expert) => expert !== action.payload);
      const tempTotal = state.sessions.total;

      state.experts.result = [...temp];
      state.experts.total = tempTotal - 1;
      state.expertIds = [...tempIds];
      state.loading = false;
    },
    deleteExpertFailed(state: EditGroupState) {
      state.loading = false;
    },
    // users
    getUsers(state: EditGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.subStateLoading = true;
    },
    getUsersSucceeded(state: EditGroupState, action: PayloadAction<IFilteredUsers>) {
      state.users = action.payload;
      state.subStateLoading = false;
    },
    getUsersFailed(state: EditGroupState) {
      state.subStateLoading = false;
    },
    // delete user
    deleteUser(state: EditGroupState, _action: PayloadAction<string>) {
      state.loading = true;
    },
    deleteUserSucceeded(state: EditGroupState, action: PayloadAction<string>) {
      const temp = state.users.result.filter((userItem) => userItem.email !== action.payload);
      state.users.result = [...temp];
      state.loading = false;
    },
    deleteUserFailed(state: EditGroupState) {
      state.loading = false;
    },
    // delete session
    deleteSession(
      state: EditGroupState,
      _action: PayloadAction<{ sessionId: string; expertId: string }>,
    ) {
      state.loading = true;
    },
    deleteSessionSucceeded(
      state: EditGroupState,
      action: PayloadAction<{ sessionId: string; expertId: string }>,
    ) {
      const temp = state.sessions.result.filter(
        (sessionItem) => sessionItem.id !== action.payload.sessionId,
      );
      const tempTotal = state.sessions.total;
      const tempIds = state.sessionIds.filter((s) => s.session !== action.payload.sessionId);

      state.sessions.result = [...temp];
      state.sessions.total = tempTotal - 1;
      state.sessionIds = [...tempIds];
      state.loading = false;
    },
    deleteSessionFailed(state: EditGroupState) {
      state.loading = false;
    },
    hideSession(
      state: EditGroupState,
      _action: PayloadAction<{ expertId: string; sessionId: string; isVisible: boolean }>,
    ) {
      state.loading = true;
    },
    hideSessionSucceeded(
      state: EditGroupState,
      action: PayloadAction<{ expertId: string; sessionId: string; isVisible: boolean }>,
    ) {
      const temp = [...state.sessions.result];
      const index = state.sessions.result.findIndex((s) => s.id === action.payload.sessionId);

      temp[index].isVisible = action.payload.isVisible;
      temp[index].modalState = ModalStates.close;

      state.sessions.result = [...temp];
      state.loading = false;
    },
    hideSessionFailed(state: EditGroupState) {
      state.loading = false;
    },
    // delete content
    deleteContent(
      state: EditGroupState,
      _action: PayloadAction<{ contentId: string; expertId: string }>,
    ) {
      state.loading = true;
    },
    deleteContentSucceeded(
      state: EditGroupState,
      action: PayloadAction<{ contentId: string; expertId: string }>,
    ) {
      const temp = state.content.result.filter(
        (contentItem) => contentItem.id !== action.payload.contentId,
      );
      const tempTotal = state.content.total;
      const tempIds = state.contentIds.filter(
        (contentItem) => contentItem.content !== action.payload.contentId,
      );
      state.content.result = [...temp];
      state.contentIds = [...tempIds];
      state.content.total = tempTotal - 1;
      state.loading = false;
    },
    deleteContentFailed(state: EditGroupState) {
      state.loading = false;
    },
    hideContent(
      state: EditGroupState,
      _action: PayloadAction<{ expertId: string; contentId: string; isVisible: boolean }>,
    ) {
      state.loading = true;
    },
    hideContentSucceeded(
      state: EditGroupState,
      action: PayloadAction<{ expertId: string; contentId: string; isVisible: boolean }>,
    ) {
      const temp = [...state.content.result];
      const index = state.content.result.findIndex((s) => s.id === action.payload.contentId);

      temp[index].isVisible = action.payload.isVisible;
      temp[index].modalState = ModalStates.close;

      state.content.result = [...temp];
      state.loading = false;
    },
    hideContentFailed(state: EditGroupState) {
      state.loading = false;
    },
    // delete article
    deleteArticle(
      state: EditGroupState,
      _action: PayloadAction<{ articleId: string; expertId: string }>,
    ) {
      state.loading = true;
    },
    deleteArticleSucceeded(
      state: EditGroupState,
      action: PayloadAction<{ articleId: string; expertId: string }>,
    ) {
      const temp = state.articles.result.filter(
        (articleItem) => articleItem._id !== action.payload.articleId,
      );
      const tempTotal = state.articles.total;
      const tempIds = state.articleIds.filter(
        (articleItem) => articleItem.article !== action.payload.articleId,
      );
      state.articles.result = [...temp];
      state.articleIds = [...tempIds];
      state.articles.total = tempTotal - 1;
      state.loading = false;
    },
    deleteArticleFailed(state: EditGroupState) {
      state.loading = false;
    },
  },
});

export const { actions: editGroupActions } = editGroupSlice;
